<template>
    <section class="content">
        <div class="card card-primary">
            <div class="card-body">
                <div class="row">
                    <div class="col-9">
                        <div class="row form-horizontal">
                            <div class="col-auto">
                                <label class="control-label my-2 ml-2">
                                    <h6>Filter Pencarian</h6>
                                </label>
                            </div>
                            <div class="col-auto">
                                <select class="form-control" @change="dataType($event)">
                                    <option value="pickupTime">Dibuat Antara</option>
                                    <option value="updatedOn">Update Antara</option>
                                </select>
                            </div>
                            <div class="col-auto">
                                <div class="input-group mb-3">
                                    <input type="text" ref="daterange" class="form-control" style="border-right: 0" />
                                    <div class="input-group-append">
                                        <div class="input-group-text" style="background-color: #fff">
                                            <span><span class="fa fa-calendar"></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto">
                                <button class="btn btn-info dropdown-icon  dropdown-toggle" v-bind:class="btnFilter" @click="selectStatus">Status </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 text-right">
                        <div class="btn-group" ref="toolbar">
                            <button type="button" data-action="read" class="btn btn-default"><i class="fa fa-sync-alt"></i></button>
                            <button type="button" :disabled="downloading" v-on:click="downloadXlsx" data-action="export-xlsx" class="btn btn-default"><i class="fa fa-download"></i> Download</button>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                <table class="table table-hover" ref="tableuser" id="tblriwayat">
                    <thead>
                        <tr>
                            <th>No. Order</th>
                            <th>No. Resi</th>
                            <th>Shipper</th>
                            <th>Tujuan</th>
                            <th>Kurir</th>
                            <th>Tanggal</th>
                            <th>Status</th>
                            <th>Ticket</th>
                        </tr>
                    </thead>
                    <tbody @click="handleClick">
                    </tbody>
                </table>
                </div>
            </div>
        </div>
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit="submitForm">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ formTitle }}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <!-- <li v-for="error in errors">{{ error }}</li> -->
                                </ul>
                            </p>
                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Username</label>
                                    <input type="hidden" name="row_id" value="" />
                                    <input id="username" class="form-control" v-model="form.username" type="text" required="required" name="username" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Group</label>
                                    <select v-model="form.role_id" class="form-control">
                                        <!-- <option v-for="role in groups" v-bind:value="role.id">{{ role.label }}</option> -->
                                    </select>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Display Name</label>
                                    <input id="name" class="form-control" v-model="form.display_name" type="text" required="required" name="i_name" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Email</label>
                                    <input id="email" class="form-control" v-model="form.email" type="text" required="required" name="i_email" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Password</label>
                                    <input id="pwd" class="form-control" value="" type="password" name="i_pwd" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label"> </label>
                                    <label class="checkbox control-label">
                                        <input value="1" checked="checked" type="checkbox" name="active" /> Active</label>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary">Save changes</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <Historyfilter :show="show" :sOther="false" v-model="filter" v-on:change="updateFilter" />
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import Historyfilter from "@/dialog/Historyfilter";
import moment from "moment";

export default {
  name: "Riwayat",
  data() {
    return {
      dateStart: moment().day(-31).format("YYYY-MM-DD"),
      dateEnd: moment().format("YYYY-MM-DD"),
      errors: [],
      method: "",
      roles: "",
      groups: [],
      formTitle: "Tambah User",
      form: {
        name: "",
        username: "",
        email: "",
      },
      downloading: false,
      show: false,
      filter: {},
      data_type: "pickupTime",
      dt1: moment().startOf("month"),
      dt2: moment(),
      btnFilter: 'btn-default'
    };
  },
  computed: {
    kurirLogo() {
      const fileName = "jne"; //logo.toLowerCase()

      return require(`../assets/img/${fileName}.png`); // the module request
    },
  },
  components: {
    Historyfilter,
  },
  watch:{
    $route (to, from){
      if(to.path === "/riwayat-kurir/all")
      {
        sessionStorage.filterData='';
		    sessionStorage.filterStatus = '';
		    this.btnFilter= 'btn-default';
        this.filter = {};
        this.table.api().ajax.reload();
        $('.loading-overlay').removeClass('show');
      }
    }
  },
  created: function () {
    console.log("filter: ",this.$route.params);
    this.roles = this.$route.meta.roles;
    this.userInfo = auth.user();
    if(this.$route.path === "/riwayat-kurir/all")
    {
      sessionStorage.filterData='';
      sessionStorage.filterStatus = '';
    }
    if(this.$route.params.filter)
      this.filter = this.$route.params.filter;
    if(Object.keys(this.filter).length<1)
    {
      if(sessionStorage.filterData)this.filter = JSON.parse(sessionStorage.filterData);
    }
    else{
      sessionStorage.filterData = JSON.stringify(this.filter);
      sessionStorage.filterStatus = 1;
    }
    if(this.filter.dt1 && this.filter.dt2)
    {
      this.dt1 = moment(this.filter.dt1);
      this.dt2 = moment(this.filter.dt2);
    }
    this.dateStart = this.dt1.format('YYYY-MM-DD');
    this.dateEnd = this.dt2.format('YYYY-MM-DD');
    if(this.filter.gid)
    {
      this.groupId = this.filter.gid;
      this.dateStart = '';
      this.dateEnd = '';
	  }
    this.filter.datatype = this.data_type;
    
    if(sessionStorage.filterStatus == 1)
    {
      this.btnFilter = 'btn-info';
    }

    authFetch("/users/groups")
      .then((res) => {
        if (res.status === 201) {
        } else if (res.status === 400) {
        }
        return res.json();
      })
      .then((js) => {
        this.groups = js.data;
      });
  },
  methods: {
    downloadXlsx: function (e) {
      this.downloading = true;
      var filter = this.filter;
      filter.q = $("input[name=q]").val();
      filter.category = $("select[name=category]").val();
      var data = Object.keys(filter)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + ((filter[key] ===false || filter[key] === undefined)?0 : encodeURIComponent(filter[key]))
        )
        .join("&");
      authFetch("/report/order/excell", {
        method: "POST",
        body: data,
      })
        .then((response) => response.blob())
        .then((blob) => {
          setTimeout(() => {
            this.downloading = false;
          }, 1000);
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = "data.xlsx";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        });
    },
    updateFilter: function (e) {
      this.filter = e;
      this.table.api().ajax.reload();
    },
    dataType(event) {
      this.data_type = event.target.value;
      this.filter.datatype = this.data_type;
      this.table.api().ajax.reload();
    },
    onDate() {
      // var params = { dt1: this.dateStart, dt2: this.dateEnd };
      // var search = new URLSearchParams(params);
      this.table.api().ajax.reload();
    },
    selectStatus: function () {
      this.show = !this.show;
      console.log("click", this.show);
    },
    getKurirLogo(kurir) {
			if (!kurir) return;
      const fileName = kurir.toLowerCase();
      return require(`../assets/img/${fileName}.png`); // the module request
    },
    handleClick(e) {
      if (e.target.matches(".link-order")) {
        let route = this.$router.resolve({
          path: "/order/detail/" + e.target.dataset.id
        });
        window.open(route.href,"_blank");
        return false;
      }
    },
    submitForm: function (ev) {
      const e = this.$refs;
      var data = Object.keys(this.form)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
        )
        .join("&");
      var urlSubmit = "/users";
      if (this.method == "PUT") urlSubmit = "/users/" + this.form.id;

      authFetch(urlSubmit, {
        method: this.method,
        body: data,
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.errors = [];
          if (!js.success) {
            console.log(js.details);

            for (var key in js.details) {
              if (js.details.hasOwnProperty(key)) {
                this.errors.push(js.details[key]);
              }
            }

            return;
          }
          this.table.api().ajax.reload();
          $(e.formDialog).modal("hide");
        });

      ev.preventDefault();
    },
		infoClaim(claim){
			var statusClaim = '';
			if (claim == 1)
				statusClaim = '<span class="badge badge-info">Paket Diclaim</span>';
			else if (claim == 2)
				statusClaim = '<span class="badge badge-success">Claim disetujui</span>';
			else if (claim == 3)
				statusClaim = '<span class="badge badge-danger">Claim ditolak</span>';
			return statusClaim;
		},
		infoStatus(track) 
		{
			var output = '';
      var ongkir = '';

			if (track.updated == 1){
				ongkir += ' <span class="badge" style="color: #F86F15; background-color: #FFD68A"><i class="fi fi-rr-triangle-warning justify-content-center align-items-center" bold></i> Perubahan Ongkir</span>'
			}

			if (track.cancel == 1)
			{
				output += '<span class="badge badge-danger">Cancel</span>';
			}
			else if (track.pickupStatus == 0)
			{
				output += '<span class="badge badge-inverse">DRAFT</span>';
			}
			else if (track.orderStatus == 2)
			{
				output += '<span class="badge bg-indigo">Void</span>';
			}
			else
			{
				if (track.trackingStatus == 0)
				{
          if(track.order_type == 1){
            output += '<span class="badge badge-secondary">Menunggu Drop</span>';
          } else {
            output += '<span class="badge badge-secondary">Menunggu Pickup</span>';
          }
					output += this.infoClaim(track.retClaim);
				}
				else if (track.trackingStatus == 1)
				{
					output += '<span class="badge badge-warning">Proses</span>';
					output += this.infoClaim(track.retClaim);
				}
				else if (track.trackingStatus == 2)
				{
					output += '<span class="badge badge-success">Terkirim</span>';
					output += this.infoClaim(track.retClaim);
				}
				else if (track.trackingStatus == 3)
				{
					output += '<span class="badge badge-warning">Proses Retur</span>';
					output += this.infoClaim(track.retClaim);
				}
				else if (track.trackingStatus == 4) {
					if (track.retStatus == 1) 
					{
						output += '<span class="badge badge-success">Retur Diterima</span>';
						output += this.infoClaim(track.retClaim);
					}
					else if (track.retStatus == 0)
					{
						output += '<span class="badge badge-info">Retur Diserahkan</span>';
						output += this.infoClaim(track.retClaim);
					}
				}
				else if (track.trackingStatus == 5) 
				{
					output += '<span class="badge badge-danger">Bermasalah</span>';
					output += this.infoClaim(track.retClaim);
				}

        if (track.special_claim > 0) {
					output += '<span style="border-width: thin;border-style: solid;" class="badge border-' + (track.special_claim == 1 ? 'info text-info">Request' : track.special_claim == 2 ? 'success text-success">Approved' : track.special_claim == 3 ? 'danger text-danger">Rejected' : 'warning text-warning">Unknown') + ' Special Claim</span>';
				}
			}
			// var output = '';
			// output += this.statusTracking;
			// output += this.statusClaim;
			return output+ongkir;
		},
  },
  mounted() {
    const e = this.$refs;
    const self = this;
    new Daterangepicker(
      this.$refs.daterange,
      {
        startDate: !this.dt1 ? moment().day(-31) : this.dt1,
        endDate: !this.dt2 ? moment() : this.dt2,
        locale: {
					format: 'DD/MM/YYYY'
				},
      },
      function (d1, d2) {
        self.dateStart = d1.format("YYYY-MM-DD");
        self.dateEnd = d2.format("YYYY-MM-DD");
        self.data_type = self.data_type;
        self.filter.datatype = self.data_type;
        self.filter.dt1 = self.dateStart;
        self.filter.dt2 = self.dateEnd;
        self.onDate();
      }
    );
    console.log("logo", self.getKurirLogo("jne"));
    var isShipper = this.userInfo.role_id > 10;
    var isKurir = this.userInfo.role_id == 9;
    this.table = createTable(e.tableuser, {
      title: "Data Riwayat",
      roles: this.$route.params.roles,
      ajax: "/order/courier/riwayat",
      frame: false,
      toolbarEl: e.toolbar,
      lengthChange: true,
      filter: true,
      processing: true,
      stateSave: true,
      language: {
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
      filterBy: [0, 1, 2, 3],
      paramData: function (d) {
        var filter = JSON.parse(JSON.stringify(self.filter));
        for (const key in filter) {

            d[key] = filter[key] === false ? 0: filter[key];
        }
      },
      columns: [
        { data: "order_code" },
        { data: "billNo" },
        {
          data: "shipperName",
          title: isShipper ? "Asal Gudang" : "Shipper",
          render: function (data, type, row, meta) {
            return row.senderName + "<br>" + row.senderCity;
          },
        },
        {
          data: "destCity",
          render: function (data, type, row, meta) {
            return row.kecamatan + ", " + row.destCity;
          },
        },
        {
          data: "courierCode",
          visible: isKurir ? false : true,
          render: function (data, type, row, meta) {
						if (!data) return '';
            var logo = data.toLowerCase();
            return '<img height="16" src="' + self.getKurirLogo(logo) + '" />';
          },
        },
        {
          data: "updatedOn",
          render: function (data, type, row, meta) {
            return row.pickupTime;
          },
        },
        {
          data: "pickupStatus",
          render: function (data, type, row, meta) {
            return self.infoStatus(row);
          },
        },
        {
          data: "ticket_status",
          render: function (data, type, row, meta) {
            if (data == 0) {
              return "";
            } else if (data == 1) {
              return '<span class="badge badge-info">Open</span>';
            } else if (data == 2) {
              return '<span class="badge badge-success">Closed</span>';
            } else if (data == 3) {
              return '<span class="badge badge-warning">Pending</span>';
            } else {
              return "";
            }
          },
        },
      ],
			createdRow: function (row, data, dataIndex) {
				if (data['special_claim'] == 1) {
					$(row).css('background-color', '#F7EBEB');
				}
			},
      rowCallback: function (row, data) {
        $("td:eq(0)", row).html(
          '<a class="link link-order text-info" style="cursor:pointer;" data-id="' +
            data.order_no +
            '">' +
            data.order_code +
            "</a>"
        );
      },
      buttonClick: (evt) => {
        if (evt.role == "create") {
          self.form = {};
          self.method = "POST";
          self.errors = [];
          self.formTitle = "Tambah User";
          $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
          self.form = evt.data;
          self.method = "PUT";
          self.errors = [];
          self.formTitle = "Edit User";
          $(e.formDialog).modal("show");
        } else if (evt.role == "delete" && evt.data) {
          if (evt.data.pickupStatus > 0) {
            Swal.fire({
              title: "Pemberitahuan",
              text: "Data Pesanan tidak bisa dihapus",
            });
            return;
          }

          Swal.fire({
            title: "Hapus data?",
            text: "Data pesanan akan dibatalkan",
            icon: "question",
            denyButtonText: '<i class="fa fa-times"></i> Hapus',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isDenied) {
              authFetch("/order/" + evt.data.id, {
                method: "DELETE",
                body: "id=" + evt.data.id,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  this.table.api().ajax.reload();
                });

              //Swal.fire('Hapus!', '', 'success')
            }
          });
        }
      },
      initComplete: function () {
        $('.loading-overlay').removeClass('show');
      }
    });
  },
};
</script>